// React imports

// Remix imports
import { Outlet } from '@remix-run/react'

// Third party imports

// App imports
import Container from '~/components/containers/Container'
import Footer from '~/components/nav/Footer'
import NavigationMainNew, {
  MAIN_NAV_HEIGHT_MOBILE,
} from '~/components/nav/NavigationMainNew'

// Generated imports

export default function BuyerLayout() {
  return (
    <div className="flex h-full flex-col">
      <NavigationMainNew />
      <div className="flex-grow">
        <Container
          // MAIN_NAV_HEIGHT_MOBILE is the height of the fixed main nav plus some top margin
          className={`${MAIN_NAV_HEIGHT_MOBILE} pb-next-section md:my-next-group-element-2x `}
        >
          <Outlet />
        </Container>
      </div>
      <Footer />
    </div>
  )
}
